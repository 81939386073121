import Routes from "./routes";
// import VConsole from "vconsole";

// new VConsole();

const App = () => {
  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;