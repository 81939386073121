import axios from "axios";

const UserAuthService = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/user/auth`,
});

const UserService = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/user`,
});

const Service = {
  login: (data) => UserService.post("/login", data),
  profile: (headers) => UserAuthService.get("/profile", headers),
  check_user: (uid) =>
    UserService.get("/check", {
      headers: {
        UID: uid,
      },
    }),
};

export default Service;
