import React, { Component } from "react";

import LineLogo from "../assets/icons/line.png"
import liff from "@line/liff";

const withLine = (WrappedComponent) => {
  return class ComponentWithLine extends Component {
    constructor(props) {
      super(props);
      this.state = {
        profile: null,
      };
    }

    LineInit = async () => {
      let liffId = process.env.REACT_APP_LIFF_ID;
      await liff.init({ liffId }).then(async (_) => {
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          this.setState({
            profile: profile,
          });
        } else {
          liff.login();
        }
      });
    };

    componentDidMount() {
      if (liff.isInClient()) {
        this.LineInit();
      }
    }

    render() {
      if (liff.isInClient()) {
        if (this.state.profile) {
          return (
            <WrappedComponent
              {...this.props}
              line_profile={this.state.profile}
            />
          );
        } else {
          return <div></div>;
        }
      } else {
        return (
          <div className="min-h-screen flex items-center justify-center bg-blueGray-100">
            <div>
              <img src={LineLogo} className="mx-auto mb-5" width="50" />
              <span>Please open in line application</span>
            </div>
          </div>
        );
      }
    }
  };
};

export default withLine;
