import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import ReactLoading from "react-loading";
import TagName from "../assets/icons/tagname.png";
import UserService from "../services/UserService";
import cookie from "react-cookies";
import { useHistory } from "react-router";
import withCheckUser from "../hoc/withCheckUser";
import withLine from "../hoc/withLine";

function Profile({ line_profile }) {
  const [profile, setProfile] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    let headers = {
      headers: {
        Authorization: `Bearer ${cookie.load("token")}`,
      },
    };
    await UserService.profile(headers)
      .then((res) => {
        setProfile(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          cookie.remove("token");
          history.push("/login");
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Oryor Society - โปรไฟล์</title>
        <meta name="description" content="โปรไฟล์" />
      </Helmet>
      <div className="flex items-center justify-center h-screen bg-blueGray-100">
        {profile ? (
          <>
            <div className="p-8 w-full">
              <div className="w-full bg-white shadow-lg rounded-lg my-20">
                <div className="py-4 px-8">
                  <div className="flex justify-center -mt-20 pb-5">
                    <img
                      className="w-32 h-32 object-cover rounded-full"
                      src={
                        line_profile?.pictureUrl ||
                        "https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                      }
                    />
                  </div>
                  <div>
                    <p className="text-subgray text-xl font-semibold text-center">
                      {profile.employee_no}
                    </p>
                    <h2 className="text-subgray-dark text-3xl font-semibold text-center">
                      {profile.first_name}
                    </h2>
                    <h2 className="text-subgray-dark text-3xl font-semibold text-center">
                      {profile.last_name}
                    </h2>
                  </div>
                </div>
                <div className="bg-gray-50 rounded-b-lg py-4 px-8">
                  <div className="flex items-start">
                    <img src={TagName} className="pr-4 pt-0.5" />
                    <div>
                      <p className="text-subgray text-base font-semibold">
                        {profile.position || "บุคลากร"}
                      </p>
                      <p className="text-subgray text-base">
                        {profile.organize.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <ReactLoading
              type={"balls"}
              color={"#B4BECB"}
              width={50}
              height={20}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default withLine(withCheckUser(Profile, false));
