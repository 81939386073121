import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import UserService from "../services/UserService";
import cookie from "react-cookies";

const withAuth = (WrappedComponent, isLoginPage) => {
  return class ComponentWithAuth extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isChecked: false,
        isUser: false,
      };
    }

    componentWillMount() {
      this.checkUser();
    }

    checkUser = async () => {
      await UserService.check_user(this.props.line_profile.userId)
        .then((res) => {
          cookie.save("token", res.data.token);
          this.setState({ isUser: true, isChecked: true });
        })
        .catch((_) => {
          cookie.remove("token");
          this.setState({ isChecked: true });
        });
    };

    render() {
      if (this.state.isChecked) {
        if (isLoginPage) {
          if (this.state.isUser) {
            return <Redirect exact to={`/profile`} />;
          } else {
            return <WrappedComponent {...this.props} />;
          }
        } else {
          if (this.state.isUser) {
            return <WrappedComponent {...this.props} />;
          } else {
            return <Redirect exact to={`/login`} />;
          }
        }
      } else {
        return <div></div>;
      }
    }
  };
};

export default withAuth;
