import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Home from "./pages/index";
import Login from "./pages/login";
import Profile from "./pages/profile";

const Routes = () => {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/" render={(props) => <HomePage {...props} />} /> */}
        <Route
          exact
          path="/profile"
          render={(props) => <Profile {...props} />}
        />
        <Route exact path="/Login" render={(props) => <Login {...props} />} />
        <Route path="/" render={(props) => <Home {...props} />} />
      </Switch>
    </Router>
  );
};

export default Routes;
