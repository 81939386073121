import { Helmet } from "react-helmet";
import withLine from "../hoc/withLine";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Oryor Society</title>
        <meta name="description" content="Oryor Society" />
      </Helmet>
      <div className="min-h-screen flex items-center justify-center bg-blueGray-100">
        <div>Oryor Society</div>
      </div>
    </>
  );
};

export default withLine(Home);
