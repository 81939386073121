import { Helmet } from "react-helmet";
import UserService from "../services/UserService";
import cookie from "react-cookies";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import withCheckUser from "../hoc/withCheckUser";
import withLine from "../hoc/withLine";

const Login = ({ line_profile }) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState(null);

  const onLogin = (data) => {
    let dataLogin = {
      employee_no: data.employee_no,
      auth_key: data.auth_key,
      uid: line_profile.userId,
    };
    UserService.login(dataLogin)
      .then((res) => {
        cookie.save("token", res.data.token);
        history.push(`/profile`);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setError({
            message: "รหัสประจำตัว หรือรหัสผ่านของคุณไม่ถูกต้อง",
          });
        }
        // window.alert("err status ", err.response.status);
      });
  };

  return (
    <>
      <Helmet>
        <title>Oryor Society - เข้าสู่ระบบ</title>
        <meta name="description" content="เข้าสู่ระบบ" />
      </Helmet>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 px-8 py-12 bg-blueGray-100">
        <form onSubmit={handleSubmit(onLogin)}>
          <div className="w-full space-y-5">
            <div className="font-medium text-subgray text-center">
              ท่านสามารถเข้าสู่ระบบ
              <br />
              โดยกรอกรหัสประจำตัว และรหัสผ่านที่ได้รับ
            </div>
            <div>
              <p className="font-bold text-subgray-darkest text-sm">
                รหัสประจำตัว
              </p>
              <input
                className={`appearance-none border ${
                  error && "border-red-600"
                } rounded w-full p-3 text-gray-500 leading-tight mt-0`}
                id="username"
                type="text"
                placeholder="รหัสประจำตัว 5 หลัก"
                {...register("employee_no")}
              />
            </div>
            <div className="space-y-2">
              <div>
                <p className="font-bold text-subgray-darkest text-sm">
                  รหัสผ่าน
                </p>
                <input
                  className={`appearance-none border ${
                    error && "border-red-600"
                  } rounded w-full p-3 text-gray-500 leading-tight mt-0`}
                  id="password"
                  type="password"
                  placeholder="รหัสผ่าน"
                  {...register("auth_key")}
                />
              </div>
              <p className={`text-red-600 ${!error && "hidden"}`}>
                {error?.message}
              </p>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-buttonblack hover:bg-blue-700 text-white font-bold py-2 px-4 my-7 rounded"
          >
            เข้าสู่ระบบ
          </button>
        </form>
      </div>
    </>
  );
};

export default withLine(withCheckUser(Login, true));